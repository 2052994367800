import React from 'react';
import PropTypes from 'prop-types';

import Image from '../Image';
import SliderHeroImage from '../Slider';

import './styles.scss';

/**
 *
 * @visibleName Images Slider
 */
class ImagesSlider extends React.Component {
    constructor(props) {
        super(props);
        this.createSlides = this.createSlides.bind(this);
    }

    createSlides() {
        const className = 'image-slider';
        const list = [];

        for (let i = 1; i <= this.props.slideAmount; i += 1) {
            if (this.props[`imgSrc${i}`] !== '') {
                const imgSrc = this.props[`imgSrc${i}`];
                const imgAlt = this.props[`imgAlt${i}`];
                const imgTitle = this.props[`imgTitle${i}`];
                if (imgSrc !== '') {
                    const imageElement = (
                        <Image
                            key={`slide::${i}`}
                            className={`${className}__img`}
                            imgSrc={imgSrc}
                            imgAlt={imgAlt}
                            imgTitle={imgTitle}
                            imgSrcSet={[
                                `${imgSrc} 320w`,
                                `${imgSrc} 500w`,
                                `${imgSrc} 640w`,
                                `${imgSrc} 768w`,
                                `${imgSrc} 900w`,
                                `${imgSrc} 1024w`,
                                `${imgSrc} 1366w`,
                                `${imgSrc} 1600w`,
                                `${imgSrc} 1920w`,
                                `${imgSrc} 2048w`,
                                `${imgSrc} 3072w`,
                            ]}
                            imgSizes={
                                '(min-width: 1536px) 1920px,' +
                                '(min-width: 1140px) 100vw,' +
                                '(min-width: 900px) 100vw,' +
                                '(min-width: 600px) 100vw, 100vw'
                            }
                        />
                    );
                    list.push(imageElement);
                }
            }
        }
        return list;
    }

    render() {
        const className = 'image-slider';

        return (
            <div className="g-container">
                <div className="g-row">
                    <div className="g-col-xl-8 g-offset-xl-2 g-col-lg-4 g-offset-lg-1 g-col-md-4 g-col-sm-2 ">
                        <div className={className}>
                            <div className={`${className}__container`}>
                                <SliderHeroImage fade autoplay autoplaySpeed={10000}>
                                    {this.createSlides()}
                                </SliderHeroImage>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


ImagesSlider.formFields = Object.assign({}, {
    imgSrc1: <input />,
    imgAlt1: <input />,
    imgTitle1: <input />,

    imgSrc2: <input />,
    imgAlt2: <input />,
    imgTitle2: <input />,

    imgSrc3: <input />,
    imgAlt3: <input />,
    imgTitle3: <input />,

    imgSrc4: <input />,
    imgAlt4: <input />,
    imgTitle4: <input />,

    imgSrc5: <input />,
    imgAlt5: <input />,
    imgTitle5: <input />,

    imgSrc6: <input />,
    imgAlt6: <input />,
    imgTitle6: <input />,

    imgSrc7: <input />,
    imgAlt7: <input />,
    imgTitle7: <input />,

    imgSrc8: <input />,
    imgAlt8: <input />,
    imgTitle8: <input />,

    imgSrc9: <input />,
    imgAlt9: <input />,
    imgTitle9: <input />,

    imgSrc10: <input />,
    imgAlt10: <input />,
    imgTitle10: <input />,
});

ImagesSlider.propTypes = {
    slideAmount: PropTypes.number,

    /* eslint-disable */
    // getting of the properties made by key
    imgSrc1: PropTypes.string,
    imgAlt1: PropTypes.string,
    imgTitle1: PropTypes.string,

    imgSrc2: PropTypes.string,
    imgAlt2: PropTypes.string,
    imgTitle2: PropTypes.string,

    imgSrc3: PropTypes.string,
    imgAlt3: PropTypes.string,
    imgTitle3: PropTypes.string,

    imgSrc4: PropTypes.string,
    imgAlt4: PropTypes.string,
    imgTitle4: PropTypes.string,

    imgSrc5: PropTypes.string,
    imgAlt5: PropTypes.string,
    imgTitle5: PropTypes.string,

    imgSrc6: PropTypes.string,
    imgAlt6: PropTypes.string,
    imgTitle6: PropTypes.string,

    imgSrc7: PropTypes.string,
    imgAlt7: PropTypes.string,
    imgTitle7: PropTypes.string,

    imgSrc8: PropTypes.string,
    imgAlt8: PropTypes.string,
    imgTitle8: PropTypes.string,

    imgSrc9: PropTypes.string,
    imgAlt9: PropTypes.string,
    imgTitle9: PropTypes.string,

    imgSrc10: PropTypes.string,
    imgAlt10: PropTypes.string,
    imgTitle10: PropTypes.string,
    /* eslint-enable */
};

ImagesSlider.defaultProps = {
    slideAmount: 10,

    imgSrc1: '/images/img5.jpg',
    imgAlt1: 'Default alt text',
    imgTitle1: 'Default title text',

    imgSrc2: '',
    imgAlt2: '',
    imgTitle2: '',

    imgSrc3: '',
    imgAlt3: '',
    imgTitle3: '',

    imgSrc4: '',
    imgAlt4: '',
    imgTitle4: '',

    imgSrc5: '',
    imgAlt5: '',
    imgTitle5: '',

    imgSrc6: '',
    imgAlt6: '',
    imgTitle6: '',

    imgSrc7: '',
    imgAlt7: '',
    imgTitle7: '',

    imgSrc8: '',
    imgAlt8: '',
    imgTitle8: '',

    imgSrc9: '',
    imgAlt9: '',
    imgTitle9: '',

    imgSrc10: '',
    imgAlt10: '',
    imgTitle10: '',
};

export default ImagesSlider;
